import { useContext, useEffect, useMemo } from 'react';

import { DEFAULT_FIAS_ID } from '@src/constants';
import { useRequest } from '@src/hooks';
import { IFLFlatActionTypes, Store } from '@src/store';
import { GetPrices } from '@src/types';
import { subObjectConverter } from '@src/utils';

import {
  createRisksFromPresetData,
  createSubProductsFromPresetData,
} from '../utils';

export const useRequestData = (
  autoInitPromo: boolean,
  localPromo: string | undefined
) => {
  const {
    state: {
      stateFormIFLFlat: {
        selectedIProduct,
        presetData,
        risks,
        selectedRegion,
        insuranceSubproducts,
        dadaValue,
        insuranceSum,
      },
      stateAuth: { authTokens },
    },
    dispatch,
  } = useContext(Store);

  const { res: risksRes } = useRequest(
    'formIFLGetRisks',
    'post',
    '/v1/references/get-risks',
    {
      productCode: selectedIProduct?.code,
    },
    [selectedIProduct?.code]
  );

  useEffect(() => {
    if (!risks && !!presetData && !!risksRes) {
      dispatch({
        type: IFLFlatActionTypes.SetRisks,
        payload: createRisksFromPresetData(presetData, risksRes.risks),
      });
    }
  }, [risks, presetData, risksRes]);

  const activeRisksCodes = useMemo(
    () =>
      risks
        ?.filter((riskItem) => {
          if (riskItem.active) {
            return riskItem;
          }
        })
        .map((filteredRiskItem) => filteredRiskItem.code) || [],
    [risks]
  );

  const { res: subobjectsRes, error: subobjectsError } = useRequest(
    'formIFLGetSubproducts',
    'post',
    '/v1/references/get-subobjects',
    {
      productCode: selectedIProduct?.code,
      region: selectedRegion?.region,
      risks: activeRisksCodes,
    },
    [
      activeRisksCodes,
      selectedIProduct?.code,
      selectedRegion?.region,
      authTokens?.authorization?.accessToken,
    ],
    !(activeRisksCodes.length && !!presetData),
    authTokens?.authorization?.accessToken
  );

  useEffect(() => {
    if (!!presetData && !!subobjectsRes) {
      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSubproducts,
        payload: createSubProductsFromPresetData(presetData, subobjectsRes),
      });
      dispatch({
        type: IFLFlatActionTypes.SetCurrentSumMinLimit,
        payload:
          subobjectsRes.totalMinLimit ??
          selectedIProduct?.minProductLimit ??
          '0',
      });
      dispatch({
        type: IFLFlatActionTypes.SetCurrentSumMaxLimit,
        payload:
          subobjectsRes.totalMaxLimit ??
          selectedIProduct?.maxProductLimit ??
          '0',
      });

      dispatch({
        type: IFLFlatActionTypes.SetInsuranceSum,
        payload: presetData.subObjects.reduce(
          (acc, { defaultInsuranceSum }) =>
            (acc += Number(defaultInsuranceSum)),
          0
        ),
      });
    }
  }, [presetData, subobjectsRes]);

  const {
    isLoading: isPricesLoading,
    error: pricesError,
    res: pricesRes,
  } = useRequest<GetPrices>(
    'formIFLGetPricesFull',
    'post',
    '/v1/subscription/get-prices',
    {
      risks: activeRisksCodes,
      productCode: selectedIProduct?.code,
      insuranceSum: Number(insuranceSum),
      personProperties: {
        subObjects: insuranceSubproducts?.subObjects
          ? subObjectConverter(insuranceSubproducts.subObjects)
          : [],
        address: {
          regionCode: selectedRegion?.region,
          address: dadaValue?.value || '',
          addressCode: dadaValue?.data?.fias_id || DEFAULT_FIAS_ID,
        },
      },
      returnMinDuration: false,
      promoCode: autoInitPromo ? localPromo : undefined,
    },
    [
      activeRisksCodes,
      selectedRegion?.region,
      insuranceSubproducts?.subObjects,
      localPromo,
      autoInitPromo,
      dadaValue?.value,
      dadaValue?.data?.fias_id,
      selectedIProduct?.code,
      insuranceSum,
    ],
    !(activeRisksCodes && insuranceSubproducts?.subObjects.length),
    authTokens?.authorization?.accessToken
  );

  return {
    subobjectsError,
    isPricesLoading,
    pricesError,
    pricesRes,
  };
};
